import React from "react"
import Image from "gatsby-background-image"
import get from "lodash.get"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
export default function CarouselBg({ children, style }) {
  const { bg } = useStaticQuery(graphql`
    {
      bg: file(publicURL: { regex: "/carousel_bcg.jpg/" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 800, srcSetBreakpoints: [220, 360, 500, 640]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className="bgImage"
      css={css`
        .gatsby-image-wrapper {
          &:before {
            background-size: auto;
            background-position: 0% 0%;
            background-repeat: repeat;
          }
        }
      `}
    >
      <Image
        style={{
          ...style,
        }}
        fluid={["linear-gradient(#FE3B2A,#FE3B2A)"]}
        loading="eager"
      >
        {children}
      </Image>
    </div>
  )
}
