import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import $ from "jquery"
import Header from "./Header"
import Footer from "./Footer"
import TopNavbar from "./TopNavbar"
import SidebarNav from "./SidebarNav"
import Modals from "./Modals"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"

export default function Layout({ children }) {
  const { serviceMenuItems } = useStaticQuery(graphql`
    {
      serviceMenuItems: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/services/"}}) {      
        nodes {
          id
          frontmatter {
            servicecat1
            shortTitle
            slug
          }
        }      
    }
    }
  `)
  // useEffect(() => {
  //   $("html").on("swiperight", function () {
  //     $("body").addClass("toggled")
  //     $(".navbar.easy-sidebar").addClass("toggled")
  //   })
  //   $("html").on("swipeleft", function () {
  //     $("body").removeClass("toggled")
  //     $(".navbar.easy-sidebar").removeClass("toggled")
  //   })
  //   $(".side-menu-link").on("click", function () {
  //     $("body").removeClass("toggled")
  //   })
  // }, [])
  return (
    <>
      <Helmet>        
        <title>
          Softful - Software company Auckland, NZ | Website design | e-commerce
          | mobile apps | seo optimisation
        </title>

        <meta
          name="description"
          content="Softful, IT company in Auckland, offers professional software development for small business and the enterprise, web application programming, website upgrades, e-commerce and mobile apps."
        />
        <meta
          name="keywords"
          content="Softful, software development, company websites, mobile app development, seo, IT consultant, Auckland"
        />
        {/* <script src="jquery-1.9.1.min.js"></script>
        <script src="jquery.detect_swipe.js"></script>
        <script src="bootstrap.min.js"></script> */}
      </Helmet>
      <div className="content-wrapper">
        {" "}
        <Modals />
        <Header />
        <TopNavbar />
        {children}
        <Footer serviceMenuItems={serviceMenuItems} />
      </div>

      <SidebarNav serviceMenuItems={serviceMenuItems} />
    </>
  )
}
