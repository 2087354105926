import React from "react"
import { Link } from "gatsby"
import slugify from "../util/slugify"

export default function SidebarNav({serviceMenuItems}) {
  return (
    <nav className="navbar navbar-inverse easy-sidebar ">
      <button
        type="button"
        className="navbar-toggle easy-sidebar-toggle"
        aria-expanded="false"
        onClick={(e) => {
          window.$("body").removeClass("toggled")
          window.$(".navbar.easy-sidebar").removeClass("toggled")
        }}
      >
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar" /> <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
      <div className="side-menu-holder">
        <ul className="side-menu">
          <li>
            <Link className="side-menu-link" to="/">
              home
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <Link to="/services/" className="side-menu-link">
              services
            </Link>
          </li>
          {serviceMenuItems.nodes.map((s) =>
          <li className="subl">
                <Link to={`/services/${slugify(s.frontmatter.servicecat1)}/${s.frontmatter.slug}`} className="side-menu-link">
                  {s.frontmatter.shortTitle}
                </Link>
                </li>)}          
        </ul>
        <ul className="side-menu">
          <li>
            <Link className="side-menu-link" to="/portfolio/">
              Portfolio
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <Link className="side-menu-link" to="/reviews/">
              Reviews
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <Link className="side-menu-link" to="/about/">
              About
            </Link>
          </li>
          <li className="subl">
            <Link to="/about/" className="side-menu-link">
              – Our team
            </Link>
          </li>
          <li className="subl">
            <Link to="/about/" className="side-menu-link">
              – Testimonials
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <Link to="/contacts/" className="side-menu-link">
              Contacts
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <Link to="/blog/" className="side-menu-link">
              News
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
