import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import logo from "../images/logo_text_nobg.svg"
import $ from "jquery"
export default function Header() {
  // const { logoold } = useStaticQuery(graphql`
  //   {
  //     logoold: file(publicURL: { regex: "/logo_text.svg/" })
  //   }
  // `)
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 text-center">
            <Link to="/">
              {" "}
              <img src={logo} className="logo" />
            </Link>
          </div>
          <div className="col-sm-4">
            <div className="telephone-holder">
              <div className="clear" />
              <a
                href="#"
                className="callback"
                data-toggle="modal"
                data-target="#callbackModal"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                Request a call
              </a>
            </div>
          </div>
          <div className="col-sm-4">
            <Link to="/contacts/#sendRequestBlock" className="request">
              Request a free quote
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
