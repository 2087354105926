import React from "react"
import { Link } from "gatsby"
import navbarIcon from "../images/navbar-icon.gif"
import $ from "jquery"
export default function TopNavbar() {
  return (
    <div className="navbar navbar-default" role="navigation" id="navgation">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle easy-sidebar-toggle"
            onClick={(e) => {
              e.preventDefault()
              $("body").toggleClass("toggled")

              $(".navbar.easy-sidebar").toggleClass("toggled")
            }}
          >
            {" "}
            <span className="icon-bar" /> <span className="icon-bar" />{" "}
            <span className="icon-bar" />{" "}
          </button>
        </div>
        <div
          className="shift-right collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav">
            <li>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services/" activeClassName="active">
                Services
              </Link>
            </li>
            <li>
              <Link to="/about/" activeClassName="active">
                About
              </Link>
            </li>
            <li>
              <Link to="/contacts/" activeClassName="active">
                Contacts
              </Link>
            </li>
            <li>
              <Link to="/blog/" activeClassName="active">
                Blog
              </Link>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  $("body").toggleClass("toggled")

                  $(".navbar.easy-sidebar").toggleClass("toggled")
                }}
                className="easy-sidebar-toggle spec-icon"
              >
                <img src={navbarIcon} width={29} height={21} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
