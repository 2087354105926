import React, { useEffect } from "react"
import $ from "jquery"
import * as yup from "yup"
import { useFormik } from "formik"
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export default function Modals() {
  yup.addMethod(yup.string, "or", function (schemas, msg) {
    return this.test({
      name: "or",
      message: "Please enter your email or phone!" || msg,
      test: (value) => {
        if (Array.isArray(schemas) && schemas.length > 1) {
          const resee = schemas.map((schema) => schema.isValidSync(value))
          return resee.some((res) => res)
        } else {
        }
      },
      exclusive: false,
    })
  })
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    submitForm,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      fullname: "",
      callbackContact: "",
    },
    validationSchema: yup.object({
      fullname: yup.string().required("Please enter your name!"),
      callbackContact: yup
        .string()
        .or([yup.string().email(), yup.number()])
        .required("Please enter your email or phone!"),
    }),
    onSubmit: (values) => {
      fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "Callback Form", ...values }),
      })
        .then(() => {
          $("#alertSuccess").fadeIn().fadeOut(6000)
          $("#callbackModal").modal("hide")
          resetForm()
        })

        .catch((error) => {})
    },
  })
  return (
    <div>
      <div
        id="alertSuccess"
        className="alert alert-success collapse alert-fixed"
      >
        <strong>Success!</strong> Thank you for contacting us, we will be in
        touch soon.
      </div>

      {/* Modal */}
      <div className="modal fade" id="callbackModal" role="dialog">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
              <h4 className="modal-title">Callback request</h4>
            </div>
            <div className="modal-body">
              <form
                method="POST"
                name="Callback Form"
                className="form-horizontal form-bcg"
                onSubmit={handleSubmit}
                data-netlify="true"
                id="callbackForm"
              >
                <input type="hidden" name="form-name" value="Callback Form" />
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      value={values.fullname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Your name *"
                    />
                    {touched.fullname && errors.fullname && (
                      <div className="form-error">{errors.fullname}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="callbackContact"
                      value={values.callbackContact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Phone or email *"
                    />
                    {touched.callbackContact && errors.callbackContact && (
                      <div className="form-error">{errors.callbackContact}</div>
                    )}
                  </div>
                </div>
                <button hidden></button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={submitForm}
                className="btn btn-default btn-req"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
