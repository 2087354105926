import React from "react"
import { Link } from "gatsby"
import CarouselBg from "./CarouselBg"
import slugify from "../util/slugify"
export default function Footer({ serviceMenuItems }) {
  return (
    <>
      <div>
        <CarouselBg style={{ padding: "50px 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="consult">FREE CONSULTATION</div>
                <div className="consult-2">We'll call you back!</div>
              </div>
              <div className="col-sm-6">
                <Link to="/contacts/#sendRequestBlock" className="snd-req">
                  Send Request
                </Link>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="podval">
          <div className="container">
            <div className="row">
              <div className="col-sm-2">
                <h3 className="pd-h3">Pages</h3>
                <Link to="/" className="pd-link">
                  Home
                </Link>
                <Link to="/services/" className="pd-link">
                  Service
                </Link>
                <Link to="/about/" className="pd-link">
                  About
                </Link>
              </div>
              <div className="col-sm-2 col-holder">
                {/* <Link to="/#" class="pd-link">News</Link> */}
                <Link to="/blog/" className="pd-link">
                  Blog
                </Link>
                <Link to="/contacts/" className="pd-link">
                  Contacts
                </Link>
                <Link to="/privacy-policy/" className="pd-link">
                  Privacy Policy
                </Link>
              </div>
              <div className="col-sm-5">
                <h3 className="pd-h3">Services</h3>
                {serviceMenuItems.nodes.map((s) => (
                  <Link
                    to={`/services/${slugify(s.frontmatter.servicecat1)}/${
                      s.frontmatter.slug
                    }`}
                    className="pd-link"
                  >
                    {s.frontmatter.shortTitle}
                  </Link>
                ))}
              </div>

              <div className="col-sm-3">
                <div className="telephone-holder-pd">
                  <div className="clear" />
                </div>
              </div>
              <Link to="/#" className="soc-link sc-bcg-1" />
              <Link to="/#" className="soc-link sc-bcg-2" />
              <Link to="/#" className="soc-link sc-bcg-3" />
            </div>
          </div>
        </div>
        <div className="bottom-podval">
          © {new Date().getFullYear()}. Softful Limited. All rights reserved.
        </div>
      </div>
    </>
  )
}
